<template>
  <v-container>

    <v-form v-on:submit.prevent="save" ref="form" class="pa-6" enctype="multipart/form-data">

      <v-row justify="center" dark color="primary">
        <!-- <v-toolbar min-width="100%" dark color="primary"> -->

        <v-col cols="12" md="8" lg="6">
          <!-- <v-alert type="info" class="d-flex align-items-center" v-model="showAlert" show >
              Data Saved
            </v-alert> -->
          <v-alert type="success" v-if="showAlert">
            Data Saved
          </v-alert>



          <v-text-field v-model="title" :rules="$requiredRules" label="Title*"></v-text-field>


          <v-textarea rows="5" v-model="detail" label="Detail"></v-textarea>
          <!-- <v-file-input label="Give image (example:png/jpg) " filled prepend-icon="mdi-camera" ></v-file-input> -->
          <!-- <v-file-input
      :rules="$requiredRules"
    
      accept="image/jpg,.jpeg,image/png,.gif"
      placeholder="Pick an image"  v-model="file"
      prepend-icon="mdi-camera"
      label="Upload image file "


    ></v-file-input> -->
          <!-- <v-btn color="secondary" type="submit" class="orange mx-5 float-right" @click="save_con">Save  </v-btn> -->
          <!-- <v-file-input
        v-model="file"
        accept=".doc,.docx,.pdf,.jpg,.jpeg,.png"
        chips
        show-size
        multiple
        counter
        prepend-icon=""
        prepend-inner-icon="mdi-attachment"
      ></v-file-input> -->
          <v-file-input type="file" :rules="rules" accept="image/png, image/jpeg, image/bmp" placeholder="Pick an image"
            prepend-icon="mdi-camera" label="Give image(must less than 5 mb)"
            @change="handleUpload($event.target.files)"></v-file-input>
          <v-btn color="secondary" type="submit" @click="save">Save </v-btn>

        </v-col>

        <!-- </v-toolbar> -->

      </v-row>

    </v-form>
  </v-container>
</template>

<script>

export default {
  // import jwtmaker from "../../services/jwtmaker";
  // components:{indexVue},
  // props: ["class_id"],
  data: function () {


    return {

      title: '',
      detail: '',
      imageFile: null
    };
  },

  methods: {
    // handleSubmit() {
    //       this.$emit('submit', this.model)
    //     },
    handleUpload(files) {
      this.model.imageFile = files[0];
    },

    save() {

      if (this.$refs.form.validate()) {

        const data = {
          title: this.title,
          detail: this.detail,
          imageFile: this.imageFile,



        };


        this.$api.post('/examboard/', data)
          .catch((err) => {
            console.log(err);


          });

        this.title = null;
        this.detail = null;
        this.file = null;

        // this.save();
      }


    },
    // save_con(){
    //   this.save();
    //   this.$router.push("/organization/notice/list/");
    // }


  },


};

</script>



</style>